import {
    ref, watch, onUnmounted,
    getCurrentInstance,
  } from '@vue/composition-api';
  import store from '@/store';
  import toast from '@/utils/toast';
  import { isEnableLocationModule } from '@/auth/utils';
  import { useUtils as useI18nUtils } from '@core/libs/i18n';
  import reportPropertyModule from '../reportPropertyModule';
import moment from 'moment';
  
  export default function useReportPercentageRoom() {
    const ROOM_STORE_MODULE_NAME = 'report-property';
  
    // Register module
    if (!store.hasModule(ROOM_STORE_MODULE_NAME)) {
      store.registerModule(ROOM_STORE_MODULE_NAME, reportPropertyModule);
    }
  
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ROOM_STORE_MODULE_NAME)) {
        store.unregisterModule(ROOM_STORE_MODULE_NAME);
      }
    });

    
  const vm = getCurrentInstance().proxy;
    // Use toast
    const toastification = toast();
    const { t } = useI18nUtils();
    const blankItem = {
      id: 0,
      apartment: null,
      floor: null,
      name: '',
      price: null,
      deposit: null,
      size: null,
      active: true,
    };
  
    const item = ref(JSON.parse(JSON.stringify(blankItem)));
    const resetItem = () => {
      item.value = JSON.parse(JSON.stringify(blankItem));
    };
    const rows = ref([]);
  
    // Table Handlers
    const columns = [

      {
        label: t('Tòa nhà'),
        field: 'apartmentName',
      },
      {
        label: t('Tên phòng'),
        field: 'roomName',
      },
      {
        label: t('Tên giường'),
        field: 'bedName',
        hidden: rows.value.filter(row => row.bed && row.bed.id > 0).length === 0,
      },
      {
        label: t('Tỷ lệ lấp phòng'),
        field: 'percentage',
        type: 'number',
        width: '140px',
      },
    ];
  
    // filter
    const apartment = ref(null);
    const location = ref(null);
    const month = ref(moment(new Date()).format("MM-YYYY"));
    // current selected rows
    const selectedRows = ref([]);
    // ssr
    const isLoading = ref(false);
    const totalRecords = ref(0);
    const serverParams = ref({
      searchTerm: '',
      filter: {
        month: month.value
      },
      sort: {},
      page: 1,
      perPage: 5000,
    });
  
    //   API Call
    const fetchRooms = () => {
      isLoading.value = true;
      store
        .dispatch('report-property/fetchReportPromotion', serverParams.value)
        .then(response => {
          totalRecords.value = response.data.length;
          rows.value = response.data;
          isLoading.value = false;
        })
        .catch(error => {
          toastification.showToastError(error);
          isLoading.value = false;
        });
    };
  
    const fetchData = () => {
      fetchRooms();
    };
  
    const exportData = () => {
      store
        .dispatch('report-property/exportReportPromotion', serverParams.value)
        .then(response => {
          vm.$router.push({ name: 'jobs' });
        })
        .catch(error => {
          toastification.showToastError(error);
        });
    };
  
    //   Methods
    const onViewDetailItem = val => {
      item.value = val;
    };
    const updateParams = newProps => {
      serverParams.value = { ...serverParams.value, ...newProps };
      fetchData();
    };
  
    const onPageChange = params => {
      updateParams({ page: params.currentPage });
    };
  
    const onPerPageChange = params => {
      updateParams({ perPage: params.currentPerPage });
    };
  
    const onSortChange = params => {
      updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
    };
  
    const onColumnFilter = params => {
      updateParams(params);
    };
  
    const selectionChanged = params => {
      selectedRows.value = params.selectedRows;
    };
  
    //   Watch
    watch(apartment, val => {
      const { filter } = serverParams.value;
      if (val) {
        filter.apartmentId = val.id;
      } else {
        delete filter.apartmentId;
      }
      updateParams({ filter });
    });
    watch(location, val => {
      const { filter } = serverParams.value;
      if (val) {
        filter.locationId = val.id;
      } else {
        delete filter.locationId;
      }
      updateParams({ filter });
    });
    watch(month, val => {
      const { filter } = serverParams.value;
      if (val) {
        filter.month = val;
      } else {
        delete filter.month;
      }
      updateParams({ filter });
    });
  
    return {
      item,
      columns,
      rows,
      apartment,
      month,
      selectedRows,
      isLoading,
      totalRecords,
      serverParams,
      location,
      isEnableLocationModule,
      fetchData,
      fetchRooms,
      resetItem,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,
      onViewDetailItem,
      exportData,
      t,
    };
  }
  